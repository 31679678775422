<template>
    <loading-shimmer
        v-if="loading"
        width="100%"
        height="80px" />
    <div
        v-else
        class="platform-ad-copy-input">
        <icon
            class="mr-3"
            :name="platform.icon"
            size="20"
            :color="isPinterestComponent ? '#c7cfd3' : '#8F9EA6'" />
        <div class="input-container">
            <div
                v-if="isPinterestComponent"
                class="pinterest">
                <icon
                    class="mr-2"
                    name="indicator-warning"
                    color="#FF3D00"
                    size="18" />
                <div>
                    Sorry, Pinterest does not accept dynamic copy for inventory ads.
                </div>
            </div>
            <v-textarea
                v-else
                ref="textArea"
                v-model="fieldValue"
                no-resize
                :error="error"
                :required="true"
                :rules="textAreaRules"
                class="styled-field" />
            <div class="input-footer">
                <div class="action-buttons">
                    <styled-button
                        text
                        :disabled="disableSingleRefresh || isPinterestComponent"
                        @click="regenerateAiCopy">
                        Regenerate AI Copy
                    </styled-button>
                    <styled-button
                        text
                        :disabled="disableSingleRefresh || isPinterestComponent"
                        @click="showModal = true">
                        Refine AI Copy
                    </styled-button>
                </div>
                <div
                    v-if="!isPinterestComponent"
                    class="tooltip-container mt-1">
                    <span><span v-text="fieldValue.length" />/{{ findMaxLengthField }}</span>
                    <styled-tooltip
                        class="single-icon"
                        open-delay="0"
                        close-delay="0"
                        nudge-top="10"
                        position="top">
                        <template #content>
                            {{ tooltipText }}
                        </template>
                        <icon
                            name="info"
                            color="#909FA8"
                            size="15" />
                    </styled-tooltip>
                </div>
            </div>
        </div>
        <platform-ad-copy-modal
            v-model="showModal"
            @submit-chat="refineAiCopy" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import StyledButton from '@/components/globals/StyledButton.vue';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { headlineKey, primaryTextKey } from '@/components/ad-studio/store/constants';
import { mapActions, mapMutations, mapState } from 'vuex';
import PlatformAdCopyModal
    from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/PlatformAdCopyModal/index.vue';
import LoadingShimmer from '@/components/globals/LoadingShimmer';
import EventBus from '@/event-bus';

export default {
    name: 'PlatformAdCopyInput',
    components: {
        LoadingShimmer,
        PlatformAdCopyModal,
        StyledTooltip,
        StyledButton,
        Icon,
    },
    props: {
        platform: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            showModal: false,
            error: '',
            textAreaRules: [
                (v) => v?.length <= this.findMaxLengthField || 'Please reduce the amount of characters being used in your Ad copy.',
            ],
            platformField: {
                [PLATFORM_FACEBOOK]: {
                    key: primaryTextKey,
                    value: 'Body Text'
                },
                [PLATFORM_PINTEREST]: {
                    key: headlineKey,
                    value: 'Primary Headline',
                },
                [PLATFORM_TIKTOK]: {
                    key: headlineKey,
                    value: 'Primary Headline',
                },
                [PLATFORM_SNAPCHAT]: {
                    key: headlineKey,
                    value: 'Primary Headline',
                },
            }
        };
    },
    computed: {
        ...mapState({
            ads: state => state.adDeployment.ads,
            dealerId: state => state.adDeployment.dealer_id,
            adDeploymentId: state => state.adDeployment.ad_deployment_id
        }),
        fieldValue: {
            get() {
                return this.$store.state.adDeployment.ad_copy.platform_specific[this.platform.name];
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: `ad_copy.platform_specific.${this.platform.name}`,
                    value
                });

                this.ads.forEach((ad, index) => {
                    this.setAdDeploymentValue({
                        key: `ads.${index}.configuration.platform_specific.${this.platform.name}.${this.platformFieldName.key}`,
                        value
                    });
                });
            }
        },
        adCopyRefreshCounter: {
            get() {
                return this.$store.state.adDeployment.ad_copy_refresh_counter;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'ad_copy_refresh_counter',
                    value
                });
            }
        },
        tooltipText() {
            const field = this.platformFieldName.value;

            return `This will be used to populate the ${this.platform.displayName}'s "${field}" field.`;
        },
        findMaxLengthField() {
            const fieldKey = this.platformFieldName.key;

            return lengthValidatorConstants[fieldKey]?.[this.platform.name]?.maxLength || null;
        },
        platformFieldName() {
            return this.platformField[this.platform.name];
        },
        disableSingleRefresh() {
            return this.adCopyRefreshCounter < 1;
        },
        isPinterestComponent() {
            return this.platform.name === PLATFORM_PINTEREST;
        }
    },
    async created() {
        if (!this.isPinterestComponent) {
            EventBus.$on('regenerate-ai-copy', this.regenerateAiCopy);
            EventBus.$on('refine-ai-copy', this.refineAiCopy);
            EventBus.$on('update-copy', (platform, copy) => {
                if (platform === this.platform.name) {
                    this.fieldValue = copy;
                }
            });
        }

        if (!this.fieldValue && !this.isPinterestComponent) {
            try {
                this.loading = true;

                this.fieldValue = await this.openAiChat(false, true, '');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        if (this.fieldValue) {
            this.error = this.$refs.textArea.validate();
        }
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        ...mapActions({
            saveAdDeployment: 'adDeployment/save'
        }),
        async openAiChat(useHistory, useTemplate, prompt) {
            const response = await this.$apiRepository.openApiChat({
                dealerId: this.dealerId,
                useHistory,
                useTemplate,
                content: prompt,
                platform: this.platform.name,
                service: 'ad_deployment',
                serviceId: this.adDeploymentId
            });

            return response?.content;
        },
        async regenerateAiCopy(isSingleGeneration = true) {
            try {
                this.loading = true;

                const prompt = 'Regenerate this copy: ' + this.fieldValue;
                this.fieldValue = await this.openAiChat(false, false, prompt);

                if (isSingleGeneration) {
                    this.adCopyRefreshCounter = this.adCopyRefreshCounter - 1;
                }
            } catch (e) {
                console.error(e);
            } finally {
                await this.saveAdDeployment();

                this.loading = false;
            }
        },
        async refineAiCopy(event, isSingleGeneration = true) {
            try {
                this.loading = true;

                const prompt = 'Refine this copy: ' + this.fieldValue + ' with the given instructions: ' + event;
                this.fieldValue = await this.openAiChat(true, false, prompt);

                if (isSingleGeneration) {
                    this.adCopyRefreshCounter = this.adCopyRefreshCounter - 1;
                }
            } catch (e) {
                console.error(e);
            } finally {
                await this.saveAdDeployment();

                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.platform-ad-copy-input {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .input-container {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .pinterest {
            border: 1px solid #C7CED3;
            width: 100%;
            height: 106px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ff0000;
        }

        .styled-field {
            width: 100%;
        }

        textarea {
            background: white;
            width: 100%;
            min-height: 80px;
            outline: none;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #C7CED3;
            resize: none !important;
        }

        .tooltip-container {
            width: 100%;
            display: flex;
            right: 0;
            justify-content: flex-end;
            align-items: center;

            span {
                font-size: 12px;
            }

            .single-icon {
                cursor: pointer;
                margin-top: -6px;
                margin-left: 10px;
                display: flex;
                justify-items: center;
                align-items: center;
            }
        }

        .input-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .action-buttons {
                display: flex;
                flex-direction: row;

                button {
                    color: #00A2EA;
                    width: 110px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
