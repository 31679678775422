<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        :title="title"
        :description="description"
        :hide-articles="true">
        <complete v-if="!active" />
        <non-complete v-else />
        <styled-button
            v-if="active"
            :disabled="!valid"
            class="mt-3"
            small
            @click="goToNextStep">
            Continue
        </styled-button>
    </step-wrapper>
</template>

<script>
import StepWrapper from '@/components/ad-deployment/components/StepWrapper';
import { mapState, mapActions } from 'vuex';
import stepProps from '@/components/ad-deployment/steps/stepProps';
import NonComplete from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/NonComplete.vue';
import Complete from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/Complete.vue';
import StyledButton from '@/components/globals/StyledButton.vue';

export default {
    name: 'AdCopy',
    components: {
        StyledButton,
        NonComplete,
        Complete,
        StepWrapper,
    },
    props: stepProps,
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            agency: state => state.agency.currentAgency,
            latestStep: state => state.adDeployment.latest_step,
            specialAdsCategory: (state) => state.adDeployment.campaign_selection.all_platforms.ad_category,
            campaignName: (state) => state.adDeployment.campaign_selection.all_platforms.campaign_name,
            selection: (state) => state.adDeployment.campaign_selection.selection,
            selectedChannels: (state) => state.adDeployment.platforms,
            dealerId: (state) => state.adDeployment.dealer_id,
        }),
        title() {
            return this.$t('adDeployment.adCopy.title');
        },
        description() {
            return this.$t('adDeployment.adCopy.description');
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
    }
};
</script>

<style lang="scss" scoped>
</style>
