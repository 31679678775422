<template>
    <div class="ad-copy-exhibition">
        <icon
            class="mr-3"
            :name="platform.icon"
            size="20"
            color="#8F9EA6" />
        <div class="exhibition-container">
            <p>{{ fieldValue }}</p>
        </div>
        <edit-button step="ad_copy" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import EditButton from '@/components/ad-deployment/components/EditButton.vue';

export default {
    name: 'PlatformAdCopyExhibition',
    components: {
        Icon,
        EditButton
    },
    props: {
        platform: {
            type: Object,
            required: true,
        }
    },
    computed: {
        fieldValue() {
            return this.$store.state.adDeployment.ad_copy.platform_specific[this.platform.name];
        }
    }
};
</script>

<style lang="scss" scoped>
.ad-copy-exhibition {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .exhibition-container {
        background-color: #FFFFFF;
        width: 100%;
        min-height: 80px;
        padding: 12px 32px 8px 10px;

        p {
            line-height: 18px;
            font-size: 14px;
            color: #757575;
        }
    }
}
</style>
