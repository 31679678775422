<template>
    <div class="complete-container">
        <platform-ad-copy-exhibition
            v-for="(platform, index) in filteredPlatforms"
            :key="index"
            :platform="platform" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlatformAdCopyExhibition
    from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/PlatformAdCopyExhibition/index.vue';
import { PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    name: 'AdCopyComplete',
    components: {
        PlatformAdCopyExhibition
    },
    computed: {
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms',
        }),
        filteredPlatforms() {
            return this.activePlatforms.filter(platform => platform.name !== PLATFORM_PINTEREST);
        }
    }
};
</script>
