<template>
    <div class="wrapper">
        <div class="header">
            <p>Generate Copy</p>
            <div
                class="show-hide"
                @click="showContent = !showContent">
                <icon
                    :name="icon"
                    size="20"
                    color="#8F9EA6" />
            </div>
        </div>
        <div
            v-show="showContent"
            class="body">
            <div class="copy-buttons">
                <div class="action-buttons">
                    <styled-button
                        :disabled="disableMassRefresh"
                        @click="regenerateAiCopy">
                        <icon
                            name="tool"
                            color="white"
                            size="16" />
                        REGENERATE AI COPY
                    </styled-button>
                    <styled-button
                        :disabled="disableMassRefresh"
                        @click="showModal = true">
                        <icon
                            name="tool"
                            color="white"
                            size="16" />
                        REFINE AI COPY
                    </styled-button>
                </div>
                <div class="custom-preset">
                    <styled-button
                        text
                        @click="showPresets = !showPresets">
                        Use Custom Preset
                    </styled-button>
                </div>
            </div>
            <platform-ad-copy
                v-for="platform in activePlatforms"
                :key="platform.name"
                class="mb-4"
                :platform="platform" />
        </div>
        <div
            class="refresh-counter"
            :class="{ limit: adCopyRefreshCounter === 0 }">
            <p>Refreshes remaining: {{ adCopyRefreshCounter }}</p>
        </div>
        <platform-ad-copy-modal
            v-model="showModal"
            @submit-chat="refineAiCopy" />
        <presets-modal
            v-model="showPresets" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import PlatformAdCopy from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/PlatformAdCopyInput/index.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import StyledButton from '@/components/globals/StyledButton.vue';
import PlatformAdCopyModal
    from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/PlatformAdCopyModal/index.vue';
import EventBus from '@/event-bus';
import PresetsModal from '@/components/ad-deployment/steps/DynamicDeployment/AdCopy/PresetsModal/index.vue';

export default {
    name: 'AdCopyNonComplete',
    components: {
        PresetsModal,
        PlatformAdCopyModal,
        StyledButton,
        Icon,
        PlatformAdCopy
    },
    data() {
        return {
            showContent: true,
            showModal: false,
            showPresets: false,
        };
    },
    computed: {
        ...mapState({
            platforms: state => state.adDeployment.platforms,
        }),
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms',
        }),
        adCopyRefreshCounter: {
            get() {
                return this.$store.state.adDeployment.ad_copy_refresh_counter;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'ad_copy_refresh_counter',
                    value
                });
            }
        },
        icon() {
            return this.showContent ? 'chevron-up' : 'chevron-down';
        },
        disableMassRefresh() {
            return this.adCopyRefreshCounter < this.platforms.length;
        }
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        refineAiCopy(text) {
            this.adCopyRefreshCounter = this.adCopyRefreshCounter - this.platforms.length;

            EventBus.$emit('refine-ai-copy', text, false);
        },
        regenerateAiCopy() {
            this.adCopyRefreshCounter = this.adCopyRefreshCounter - this.platforms.length;

            EventBus.$emit('regenerate-ai-copy', false);
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    background-color: $white;
    border: 1px solid #DEDEDE;

    .header {
        position: relative;
        padding: 26px 14px 26px 14px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            font-size: 14px;
            color: #4A4A4A;
            font-weight: 600;
            margin: 0;
        }

        .show-hide {
            display: flex;
            border-left: 1px solid #DEDEDE;
            top: 0;
            width: 71px;
            right: 0;
            bottom: 0;
            position: absolute;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .body {
        padding: 20px 23px 15px 23px;

        .copy-buttons {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            margin-left: 35px;
            justify-content: space-between;

            .action-buttons {
                button {
                    padding: 0;
                    margin-right: 10px;
                    height: 25px;
                    width: 200px;
                }
            }
        }
    }

    .refresh-counter {
        height: 40px;
        padding: 10px 23px;
        display: flex;
        background: #F4F5F6;

        p {
            margin-left: auto;
        }

        &.limit {
            background: #FFCFC0;

            p {
                color: #D44C47;
            }
        }
    }
}
</style>
