<template>
    <styled-dialog
        :value="value"
        :width="600"
        @input="$emit('input', $event)">
        <h2 class="mb-2">
            Refine AI-Generated Copy
        </h2>
        <p>
            Enter instructions for the AI bot below to refine each platform's generated copy:
        </p>
        <v-textarea
            v-model="refineText"
            no-resize
            label="Enter instructions here..."
            :required="true"
            class="styled-field" />
        <p class="example-text">
            Example: "Make the tone less formal and tweak it so it appeals to first-time car buyers"
        </p>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
            <styled-button
                small
                @click="$emit('submit-chat', refineText); $emit('input', false)">
                Submit
            </styled-button>
        </template>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog.vue';
import StyledButton from '@/components/globals/StyledButton.vue';

export default {
    name: 'PlatformAdCopyModal',
    components: {
        StyledDialog,
        StyledButton
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            refineText: '',
        };
    },
};
</script>

<style lang="scss" scoped>
.dialog {
    .example-text {
        font-size: 12px;
        font-style: italic;
        color: #757575;
        margin-top: 10px;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
}
</style>
