<template>
    <styled-dialog
        :value="value"
        :width="600"
        @input="$emit('input', $event)">
        <div
            class="presets-wrapper">
            <div class="header">
                <p>Search & Select a Preset</p>
                <div
                    class="show-hide"
                    @click.prevent="$emit('input', false)">
                    <icon
                        name="close"
                        size="20"
                        color="#8F9EA6" />
                </div>
            </div>

            <div class="body">
                <div class="filters">
                    <v-text-field
                        class="styled-field"
                        label="Filter presets..."
                        @input="handleSearch" />
                    <div class="platforms">
                        <div
                            v-for="(platform, index) in filteredPlatforms"
                            :key="index">
                            <span
                                @click="togglePlatform(platform)">
                                <icon
                                    :name="platformsConfig[platform].icon"
                                    :color="platformsConfig[platform].active ? '#03A2EA' : '#909FA8'"
                                    class="mr-2"
                                    size="25" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <v-divider />

            <loading-shimmer
                v-if="loading"
                style="padding: 10px;"
                width="90%"
                height="80px" />
            <v-list
                v-else
                two-line>
                <template v-for="(preset, index) in filteredPresets">
                    <v-list-tile
                        :key="preset.id"
                        :class="{ selected: isPresetSelected(preset) }"
                        @click="selectPreset(preset)">
                        <v-list-tile-content>
                            <v-list-tile-title>{{ preset?.label }}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action>
                            <div class="platforms">
                                <icon
                                    v-for="(platform) in getPlatformsFromPreset(preset)"
                                    :key="platform"
                                    class="mr-1"
                                    :name="platformsConfig[platform].icon"
                                    color="#909FA8"
                                    size="18" />
                            </div>
                            <div
                                class="show-hide">
                                <icon
                                    :name="isPresetSelected(preset) ? 'chevron-up' : 'chevron-down'"
                                    class="ml-3"
                                    size="20"
                                    color="#8F9EA6" />
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>
                    <div
                        v-show="isPresetSelected(preset)"
                        :key="preset.id + 'description'">
                        <div
                            v-for="(platform) in getPlatformsFromPreset(preset)"
                            :key="platform"
                            class="ad-copy-exhibition">
                            <icon
                                class="mr-3"
                                :name="platformsConfig[platform].icon"
                                size="20"
                                color="#8F9EA6" />
                            <div class="exhibition-container">
                                <p>{{ preset?.configuration[platform] }}</p>
                            </div>
                        </div>
                    </div>
                    <v-divider :key="index + 'divider'" />
                </template>
            </v-list>
        </div>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
            <div class="right-side">
                <v-select
                    v-model="selectedPlatform"
                    :items="options"
                    label="Apply to"
                    class="styled-field mr-2" />
                <styled-button
                    @click="applyPreset(); $emit('input', false)">
                    Apply
                </styled-button>
            </div>
        </template>
    </styled-dialog>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import StyledDialog from '@/components/globals/StyledDialog.vue';
import StyledButton from '@/components/globals/StyledButton.vue';
import { mapState } from 'vuex';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import EventBus from '@/event-bus';
import LoadingShimmer from '@/components/globals/LoadingShimmer.vue';
import debounce from 'debounce';

export default {
    name: 'PresetsModal',
    components: {
        LoadingShimmer,
        StyledButton,
        StyledDialog,
        Icon
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            selectedPreset: null,
            selectedPlatform: 'all',
            showSlideout: true,
            loading: false,
            presets: [],
            platformsConfig: {
                [PLATFORM_FACEBOOK]: {
                    icon: 'meta',
                    active: true,
                    prettyName: 'Facebook'
                },
                [PLATFORM_PINTEREST]: {
                    icon: 'pinterest',
                    active: true,
                    prettyName: 'Pinterest'
                },
                [PLATFORM_SNAPCHAT]: {
                    icon: 'snapchat',
                    active: true,
                    prettyName: 'Snapchat'
                },
                [PLATFORM_TIKTOK]: {
                    icon: 'tiktok',
                    active: true,
                    prettyName: 'TikTok'
                },
            },
            selectOptions: [
                {
                    text: 'All Channels',
                    value: 'all'
                },
                {
                    text: 'Facebook',
                    value: 'facebook'
                },
                {
                    text: 'Snapchat',
                    value: 'snapchat'
                },
                {
                    text: 'TikTok',
                    value: 'tiktok'
                },
            ],
        };
    },
    computed: {
        ...mapState({
            platforms: state => state.adDeployment.platforms,
            agency: state => state.agency.currentAgency,
        }),
        options() {
            return this.selectOptions.filter(option => option.value === 'all' || this.platforms.includes(option.value));
        },
        filteredPlatforms() {
            return this.platforms.filter(platform => platform !== PLATFORM_PINTEREST);
        },
        activePlatforms() {
            return Object.keys(this.platformsConfig).filter(platform => this.platformsConfig[platform].active && this.filteredPlatforms.includes(platform));
        },
        filteredPresets() {
            return this.presets?.filter(preset => {
                const presetPlatforms = Object.keys(preset.configuration);

                return presetPlatforms.some(platform => this.activePlatforms.includes(platform));
            });
        }
    },
    async mounted() {
        await this.searchPresets();
    },
    methods: {
        togglePlatform(platform) {
            this.platformsConfig[platform].active = !this.platformsConfig[platform].active;
        },
        selectPreset(preset) {
            if (this.selectedPreset?.id === preset.id) {
                this.selectedPreset = null;

                return;
            }

            this.selectedPreset = preset;
        },
        getPlatformsFromPreset(preset) {
            const { configuration } = preset;

            return Object.keys(configuration).filter((platform) => {
                return this.platforms.includes(platform);
            });
        },
        applyPreset() {
            if (!this.selectedPreset) return;

            const { configuration } = this.selectedPreset;

            if (this.selectedPlatform === 'all') {
                Object.keys(configuration).forEach((platform) => {
                    EventBus.$emit('update-copy', platform, configuration[platform]);
                });
            } else {
                EventBus.$emit('update-copy', this.selectedPlatform, configuration[this.selectedPlatform]);
            }
        },
        isPresetSelected(preset) {
            return this.selectedPreset?.id === preset.id;
        },
        handleSearch: debounce(function(input) {
            this.searchPresets(input);
        }, 400),
        async searchPresets(q = '') {
            try {
                this.loading = true;
                const { data } = await this.$apiRepository.getPresets({
                    agencyId: this.agency.id,
                    q
                });

                this.presets = data;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.presets-wrapper {
    background-color: $white;
    border: 1px solid #DEDEDE;

    svg {
        cursor: pointer;
    }

    .header {
        position: relative;
        padding: 26px 14px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 14px;
            color: #4A4A4A;
            font-weight: 600;
            margin: 0;
        }
    }

    .body {
        .filters {
            display: flex;
            padding: 20px 14px;

            .styled-field {
                width: 50%;
                margin-right: 30px;
            }

            .platforms {
                width: 40%;
                display: flex;
                text-align: right;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}
</style>

<style lang="scss">
.presets-wrapper {
    .v-list {
        height: 500px;
        overflow-y: auto;
        padding: 0;

        .v-list__tile__action--stack {
            flex-direction: row;
            align-items: center;
        }

        .selected {
            background-color: #F5F5F5;
        }

        .ad-copy-exhibition {
            display: flex;
            align-items: center;
            padding: 10px;

            .exhibition-container {
                background-color: #FFFFFF;
                width: 90%;

                p {
                    line-height: 18px;
                    font-size: 14px;
                    color: #757575;
                }
            }
        }
    }

    .v-select .v-input__slot {
        min-width: 100%;
    }
}


.actions {
    .right-side {
        display: flex;
        flex-direction: row;

        select {
            min-width: 80px;
        }

        button {
            height: 34px;
        }
    }

}
</style>
