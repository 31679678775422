<template>
    <div>
        <span
            class="loading"
            :style="style" />
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '50px'
        },
        height: {
            type: String,
            default: '14px'
        }
    },
    computed: {
        style() {
            return {
                '--width': this.width,
                '--height': this.height,
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.loading {
    padding: 10px 2.5% !important;

    &::after {
        content: '';
        background: linear-gradient(to right, #F6F6F6 8%, #eae8e8 18%, #F6F6F6 33%);
        background-size: 600px 100%;
        animation: shimmer 2.5s linear infinite;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        border-radius: 7px;
    }
}

@keyframes shimmer {
    0% {
        background-position: -600px 0;
    }
    100% {
        background-position: 600px 0;
    }
}
</style>
